import qs from 'querystring';
import { Base64 } from 'js-base64';
import moment from 'moment';
import { baseURL, clientId, clientSecret } from '../../config/settings';
import { getRefreshToken } from './auth';

var AWS = require('aws-sdk');
var s3 = new AWS.S3({
  accessKeyId: 'AKIAJZLDUVQ2Q5V3Q5CQ',
  secretAccessKey: '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',
  region: 'eu-central-1',
});

//*************************************  USER  ************************************************** */
export function registration(username, password, name) {
  return async (dispatch, getState) => {
    const data = {
      method: 'POST',

      body: qs.stringify({
        username: username.toLowerCase(),
        password: password,
        fullname: name,
        scope: 'profile',
      }),
      headers: {
        Accept: 'application/json',
        //Authorization: 'Basic ' + base64.encode(clientId + ':' + clientSecret),
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    try {
      const response = await fetch(baseURL + '/users', data);
      const json = await response.json();
      if (json.hasOwnProperty('message')) {
        return dispatch({ type: 'REGISTRATION_ERROR', data: json.message });
      }
      //console.log(json);
      return dispatch({ type: 'REGISTRATION', data: json });
    } catch (error) {
      return dispatch({ type: 'REGISTRATION_ERROR', data: 'Network error' });
    }
  };
}

export function getUsers() {
  return async (dispatch, getState) => {
    const data = {
      method: 'GET',

      headers: {
        'Cache-Control': 'no-cache',
        Accept: 'application/json',
        Authorization: 'Bearer ' + getState().auth.access_token,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    try {
      const response = await fetch(baseURL + '/users', data);
      const json = await response.json();
      return dispatch({ type: 'GET_USERS', data: json });
    } catch (error) {
      return dispatch({ type: 'GET_USERS_ERROR' });
    }
  };
}
export function getMe_userdata(navigation) {
  return async (dispatch, getState) => {
    const id = getState().auth.user_id;
    const data = {
      method: 'GET',
      headers: {
        'Cache-Control': 'no-cache',
        Accept: 'application/json',
        Authorization: 'Bearer ' + getState().auth.access_token,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    try {
      const response = await fetch(baseURL + '/users/' + id, data);
      const json = await response.json();
      console.log(json);
      if (json.hasOwnProperty('message')) {
        if (json.message.indexOf('Invalid token') > -1) {
          await dispatch(getRefreshToken('getMe_userdata', navigation));
          return;
        }
      }
      return dispatch({ type: 'GET_ME_USERDATA', data: json });
    } catch (error) {
      console.log(error);

      return dispatch({ type: 'GET_ME_USERDATA_ERROR' });
    }
  };
}
export function getMe() {
  return async (dispatch, getState) => {
    const id = getState().auth.user_id;
    const data = {
      method: 'GET',
      headers: {
        'Cache-Control': 'no-cache',
        Accept: 'application/json',
        Authorization: 'Bearer ' + getState().auth.access_token,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    try {
      const response = await fetch(baseURL + '/users/data/' + id, data);
      const json = await response.json();
      //console.log('GETME!!!!!', json);
      if (json.hasOwnProperty('message')) {
        await dispatch(getRefreshToken('getMe'));
        /* const localDatabase = await AsyncStorage.getItem('@getme');
          if (localDatabase === null) {
            return getMe();
          } else {
            dispatch({
              type: 'GET_ME',
              data: JSON.parse(localDatabase),
            });
            return dispatch({type: 'GET_ME_ERROR', data: json.message});
          } */
      }
      //await AsyncStorage.setItem('@getme', JSON.stringify(json));
      return dispatch({ type: 'GET_ME', data: json });
    } catch (error) {
      console.log(error);
      //const localDatabase = await AsyncStorage.getItem('@getme');
      /* if (localDatabase === null) {
          return getMe();
        } else {
          dispatch({
            type: 'GET_ME',
            data: JSON.parse(localDatabase),
          });
          return dispatch({type: 'GET_ME_ERROR', data: 'Network error'});
        } */
    }
  };
}

export function updateUser(body) {
  return async (dispatch, getState) => {
    if (
      moment(getState().auth.date)
        .add(getState().auth.expires_in, 's')
        .isBefore(moment())
    ) {
      await dispatch(getRefreshToken('updateUser', body));
      return;
    }
    const id = getState().auth.user_id;
    //console.log('DATA' + body);

    const data = {
      method: 'PUT',

      body: qs.stringify({
        ...body,
      }),
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + getState().auth.access_token,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    //console.log('DATA' + data);
    try {
      const response = await fetch(baseURL + '/users/' + id, data);
      const json = await response.json();
      if (json.hasOwnProperty('message')) {
        return dispatch({ type: 'UPDATE_USER_ERROR', data: json.message });
      }
      return dispatch({ type: 'UPDATE_USER', data: json });
    } catch (error) {
      return dispatch({ type: 'UPDATE_USER_ERROR', data: 'Network error' });
    }
  };
}

export function deleteUser() {
  return async (dispatch, getState) => {
    if (
      moment(getState().auth.date)
        .add(getState().auth.expires_in, 's')
        .isBefore(moment())
    ) {
      await dispatch(getRefreshToken('deleteUser'));
      return;
    }
    const id = getState().auth.user_id;
    const data = {
      method: 'DELETE',

      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + getState().auth.access_token,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    try {
      const response = await fetch(baseURL + '/users/' + id, data);
      const json = await response.json();
      if (json.hasOwnProperty('message')) {
        return dispatch({ type: 'DELETE_USER_ERROR', data: json.message });
      }
      return dispatch({ type: 'DELETE_USER', data: json });
    } catch (error) {
      return dispatch({ type: 'DELETE_USER_ERROR', data: 'Network error' });
    }
  };
}

//*************************************  WORKSPACES  ************************************************** */
export function createWorkspace(title, userids) {
  return async (dispatch, getState) => {
    console.log(title, userids);
    if (
      moment(getState().auth.date)
        .add(getState().auth.expires_in, 's')
        .isBefore(moment())
    ) {
      await dispatch(getRefreshToken('createWorkspace', title, userids));
      return;
    }
    let shared = userids.map(e => {
      return { id: e.userid, img: e.img };
    });

    const body = {
      owner: getState().auth.user_id,
      title: title,
      shared: JSON.stringify(shared),
    };
    console.log(body);
    const data = {
      method: 'POST',

      body: qs.stringify({
        owner: getState().auth.user_id,
        title: title,
        shared: JSON.stringify(shared),
        projects: JSON.stringify([]),
      }),
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + getState().auth.access_token,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    try {
      const response = await fetch(baseURL + '/workspaces', data);
      console.log(response);

      const json = await response.json();
      if (json.hasOwnProperty('message')) {
        return dispatch({ type: 'CREATE_WORKSPACE_ERROR', data: json.message });
      }
      return dispatch({
        type: 'CREATE_WORKSPACE',
        workspaces: [...getState().api.workspaces, json],
        projects: [...getState().api.projects, json.projects[0]],
      });
    } catch (error) {
      console.log(error);
      return dispatch({
        type: 'CREATE_WORKSPACE_ERROR',
        data: 'Network error',
      });
    }
  };
}

export function updateWorkspace(id, title, userids) {
  return async (dispatch, getState) => {
    console.log(id, title, userids);
    if (
      moment(getState().auth.date)
        .add(getState().auth.expires_in, 's')
        .isBefore(moment())
    ) {
      await dispatch(getRefreshToken('update', title, userids));
      return;
    }
    let shared = userids.map(e => {
      return { id: e.userid, img: e.img };
    });
    console.log(shared);
    console.log(shared);
    console.log(shared);
    console.log(shared);
    const data = {
      method: 'PUT',

      body: qs.stringify({
        //projects: JSON.stringify(projects),
        title: title,
        shared: JSON.stringify(shared),
      }),
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + getState().auth.access_token,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    try {
      const response = await fetch(baseURL + '/workspaces/' + id, data);
      const json = await response.json();
      console.log(json);
      if (json.hasOwnProperty('message')) {
        return dispatch({ type: 'PUT_WORKSPACE_ERROR', data: json.message });
      }
      dispatch(getMe());
      return dispatch({
        type: 'PUT_WORKSPACE',
      });
    } catch (error) {
      console.log(error);
      return dispatch({ type: 'PUT_WORKSPACE_ERROR', data: 'Network error' });
    }
  };
}

export function add(ws_id, title) {
  return async (dispatch, getState) => {
    if (
      moment(getState().auth.date)
        .add(getState().auth.expires_in, 's')
        .isBefore(moment())
    ) {
      await dispatch(getRefreshToken('add', ws_id, title));
      return;
    }
    const data = {
      method: 'PUT',

      body: qs.stringify({
        title: title,
        color: 'color',
      }),
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + getState().auth.access_token,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    try {
      const response = await fetch(
        baseURL + '/workspaces/title/' + ws_id,
        data,
      );
      const json = await response.json();
      if (json.hasOwnProperty('message')) {
        return dispatch({
          type: 'UPDATE_WORKSPACE_TITLE_ERROR',
          data: json.message,
        });
      }
      return dispatch({ type: 'UPDATE_WORKSPACE_TITLE', data: json });
    } catch (error) {
      return dispatch({
        type: 'UPDATE_WORKSPACE_TITLE_ERROR',
        data: 'Network error',
      });
    }
  };
}

export function deleteWorkspace(ws_id, navigation) {
  return async (dispatch, getState) => {
    if (
      moment(getState().auth.date)
        .add(getState().auth.expires_in, 's')
        .isBefore(moment())
    ) {
      await dispatch(getRefreshToken('deleteWorkspace', ws_id));
      return;
    }
    const data = {
      method: 'DELETE',

      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + getState().auth.access_token,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    if (typeof window !== `undefined`) { navigation.navigate('Profile'); }
    try {
      const response = await fetch(baseURL + '/workspaces/' + ws_id, data);
      const json = await response.json();
      if (json.hasOwnProperty('message')) {
        return dispatch({ type: 'DELETE_WORKSPACE_ERROR', data: json.message });
      }
      dispatch(getMe());
      return dispatch({ type: 'DELETE_WORKSPACE', data: json });
    } catch (error) {
      return dispatch({
        type: 'DELETE_WORKSPACE_ERROR',
        data: 'Network error',
      });
    }
  };
}

//*************************************  PROJECTS  ************************************************** */

export function createProject(ws_id, title) {
  return async (dispatch, getState) => {
    if (
      moment(getState().auth.date)
        .add(getState().auth.expires_in, 's')
        .isBefore(moment())
    ) {
      await dispatch(getRefreshToken('createProject', ws_id, title));
      return;
    }
    if (!ws_id) {
      ws_id = getState().state.selectedWorkspace.id;
    }
    const data = {
      method: 'POST',

      body: qs.stringify({
        owner: getState().auth.user_id,
        workspace: ws_id,
        title: title,
        description: '',
        songs: JSON.stringify([]),
        shared: JSON.stringify([]),
      }),
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + getState().auth.access_token,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    try {
      const response = await fetch(baseURL + '/projects', data);
      const json = await response.json();
      if (json.hasOwnProperty('message')) {
        return dispatch({ type: 'CREATE_PROJECT_ERROR', data: json.message });
      }
      await dispatch(getMe());
      return dispatch({ type: 'CREATE_PROJECT', data: json });
    } catch (error) {
      return dispatch({ type: 'CREATE_PROJECT_ERROR', data: 'Network error' });
    }
  };
}

export function updateProjectSongs(pr_id, song_id) {
  return async (dispatch, getState) => {
    if (
      moment(getState().auth.date)
        .add(getState().auth.expires_in, 's')
        .isBefore(moment())
    ) {
      await dispatch(getRefreshToken('updateProjectSongs', pr_id, song_id));
      return;
    }
    const data = {
      method: 'PUT',

      body: qs.stringify({
        songs: song_id,
      }),
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + getState().auth.access_token,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    try {
      const response = await fetch(baseURL + '/projects/songs/' + pr_id, data);
      const json = await response.json();
      if (json.hasOwnProperty('message')) {
        return dispatch({
          type: 'UPDATE_PROJECT_SONGS_ERROR',
          data: json.message,
        });
      }
      return dispatch({ type: 'UPDATE_PROJECT_SONGS', data: json });
    } catch (error) {
      return dispatch({
        type: 'UPDATE_PROJECT_SONGS_ERROR',
        data: 'Network error',
      });
    }
  };
}

export function deleteProject(pr_id) {
  return async (dispatch, getState) => {
    if (
      moment(getState().auth.date)
        .add(getState().auth.expires_in, 's')
        .isBefore(moment())
    ) {
      await dispatch(getRefreshToken('deleteProject', pr_id));
      return;
    }
    const data = {
      method: 'DELETE',

      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + getState().auth.access_token,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    try {
      const response = await fetch(baseURL + '/projects/' + pr_id, data);
      const json = await response.json();
      if (json.hasOwnProperty('message')) {
        return dispatch({ type: 'DELETE_PROJECT_ERROR', data: json.message });
      }
      dispatch(getMe());
      return dispatch({ type: 'DELETE_PROJECT', data: json });
    } catch (error) {
      return dispatch({ type: 'DELETE_PROJECT_ERROR', data: 'Network error' });
    }
  };
}

//*************************************  SONGS  ************************************************** */

export function updateSong(song) {
  return async (dispatch, getState) => {
    if (
      moment(getState().auth.date)
        .add(getState().auth.expires_in, 's')
        .isBefore(moment())
    ) {
      await dispatch(getRefreshToken('updateSong', song));
      return;
    }
    if (!song) {
      song = getState().currentSoundProfile;
    }
    //console.log(song);
    let song_id = song.id;
    const data = {
      method: 'PUT',

      body: qs.stringify(song),
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + getState().auth.access_token,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    try {
      const response = await fetch(baseURL + '/songs/' + song_id, data);
      const json = await response.json();
      //console.log(json);
      if (json.hasOwnProperty('message')) {
        return dispatch({ type: 'UPDATE_SONG_ERROR', data: json.message });
      }
      dispatch({ type: 'UPDATE_SONG', data: json });
      return await dispatch(getMe());
    } catch (error) {
      return dispatch({ type: 'UPDATE_SONG_ERROR', data: 'Network error' });
    }
  };
}

export function deleteSong(song_id) {
  return async (dispatch, getState) => {
    console.log('DELETE', song_id);
    if (
      moment(getState().auth.date)
        .add(getState().auth.expires_in, 's')
        .isBefore(moment())
    ) {
      //console.log('NA');

      await dispatch(getRefreshToken('deleteSong', song_id));
      return;
    }
    const data = {
      method: 'DELETE',

      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + getState().auth.access_token,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    try {
      const response = await fetch(baseURL + '/songs/' + song_id, data);
      console.log(response);

      const json = await response.json();
      if (json.hasOwnProperty('message')) {
        return dispatch({ type: 'DELETE_SONG_ERROR', data: json.message });
      }
      dispatch(getMe());
      return dispatch({ type: 'DELETE_SONG', data: json });
    } catch (error) {
      console.log(error);
      return dispatch({ type: 'DELETE_SONG_ERROR', data: 'Network error' });
    }
  };
}
