import qs from 'querystring';
import { Base64 } from 'js-base64';
// import { useNavigate } from "@reach/router"
// import { navigate } from "@reach/router"
import { navigate } from 'gatsby';
import { baseURL, clientId, clientSecret } from '../../config/settings';
import { getMe, getMe_userdata } from './api';

import { setMessage } from './state';

let interval;

export function handleUser(prop, setCookie) {
  setCookie('user', JSON.stringify(prop), {
    path: '/',
  });
}

export function logOut(value) {
  return async (dispatch, getState) => {
    console.log(value);
    await dispatch({
      type: 'LOGOUT',
    });
  };
}

export function getTokenFromAsnycStorage(value) {
  return async (dispatch, getState) => {
    console.log(value);
    await dispatch({
      type: 'TOKEN_FROM_ASNYCSTORAGE_SUCCESS',
      data: value,
    });
    dispatch(getMe());
    dispatch(getMe_userdata());
  };
}

export function getToken(email, password, setCookie) {
  return async (dispatch, getState) => {
    console.log('MEGJÖTTÜNK');
    const data = {
      method: 'POST',
      body: qs.stringify({
        username: email.toLowerCase(),
        password,
        grant_type: 'password',
      }),
      headers: {
        Accept: 'application/json',
        Authorization: `Basic ${Base64.encode(`${clientId}:${clientSecret}`)}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    console.log('log will');
    try {
      const response = await fetch(`${baseURL}/oauth/token`, data);
      const json = await response.json();
      console.log(json);
      if (json.hasOwnProperty('message') || json.hasOwnProperty('message')) {
        dispatch(setMessage('Invalid e-mail or password. Please try again.'));
        setTimeout(() => {
          dispatch(setMessage(''));
        }, 3000);
        return dispatch({ type: 'LOGIN_FAILED', data: json.message });
      }
      json.date = new Date();
      console.log(json);
      await handleUser(JSON.stringify(json), setCookie);
      await dispatch({ type: 'LOGIN_SUCCESS', data: json });
      await dispatch(getMe_userdata());
      await dispatch(getMe());
      return dispatch({ type: 'LOGIN_SUCCESS', data: json });
    } catch (error) {
      dispatch(setMessage('No connection. Please try again later.'));
      setTimeout(() => {
        dispatch(setMessage(''));
      }, 3000);
      console.log(error);
      return dispatch({ type: 'LOGIN_FAILED', data: 'Network error' });
    }
  };
}

export function getCode() {
  return async (dispatch, getState) => {
    try {
      const data = {
        headers: {
          'cache-control': 'no-cache',
        },
      };
      const response = await fetch(`${baseURL}/loginweb`);
      const json = await response.json();
      if (json.hasOwnProperty('message') || json.hasOwnProperty('message')) {
        dispatch(setMessage('Something went wrong.'));
        setTimeout(() => {
          dispatch(setMessage(''));
        }, 3000);
        return dispatch({ type: 'LOGIN_FAILED', data: json.message });
      }
      json.date = new Date();
      dispatch({ type: 'GET_CODE', data: json.code });
      dispatch(getTokenFromCode(json.code));
    } catch (error) {
      dispatch(setMessage('No connection. Please try again later.'));
      setTimeout(() => {
        dispatch(setMessage(''));
      }, 3000);
      return dispatch({ type: 'LOGIN_FAILED', data: 'Network error' });
    }
  };
}

export function getTokenFromCode(code) {
  return async (dispatch, getState) => {
    interval = setInterval(async () => {
      let i = 0;
      i += 1;
      try {
        const response = await fetch(`${baseURL}/loginweb/${code}`);
        const json = await response.json();
        json.date = new Date();
        console.log(json);
        if (json.message === 'Code not found') {
          clearInterval(interval);
          dispatch({ type: 'GET_CODE_EXPIRED', data: json.message });
        }
        if (json.access_token) {
          console.log('GETCODE');
          console.log(json)
          clearInterval(interval);
          // should set cookie here for accesstoken
          dispatch({ type: 'GET_TOKEN_FROM_CODE', data: json });
          if (typeof window !== `undefined`) {
            dispatch(getMe());
            dispatch(getMe_userdata());
            return navigate('/dashboard');
          }
        }
      } catch (error) {
        console.log('error')
        console.log(error);
        // dispatch(setMessage('No connection. Please try again later.'));
        // setTimeout(() => {
        //  dispatch(setMessage(''));
        // }, 3000);
        // return dispatch({ type: 'LOGIN_FAILED', data: 'Network error' });
      }
    }, 5000);
  };
}

export function signOut() {
  return async (dispatch, getState) => {
    const data = {
      method: 'PUT',
      body: qs.stringify({
        notification_id: getState().api.notificationId,
      }),
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${getState().auth.access_token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    console.log(getState().api.notificationId);

    try {
      const response = await fetch(`${baseURL}/signout`, data);
      const json = await response.json();
      console.log(json);
      if (json.hasOwnProperty('message') || json.hasOwnProperty('message'))
        return dispatch({ type: 'SIGN_OUT_FAILED', data: json.message });
      await dispatch({ type: 'SIGN_OUT_SUCCESS', data: json });
      return;
    } catch (error) {
      // console.log(error);
      return dispatch({ type: 'SIGN_OUT_FAILED', data: 'Network error' });
    }
  };
}
