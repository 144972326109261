import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import { navigate } from 'gatsby';
import {
  SelectableGroup,
  createSelectable,
  SelectAll,
  DeselectAll,
} from 'react-selectable-fast';
import Switch from 'react-switch';
import AudioPlayer from 'react-h5-audio-player';
import { saveAs } from 'file-saver';

import PerfectScrollbar from 'react-perfect-scrollbar';
import SEO from '../components/seo';
import DashboardMenu from '../components/DashboardMenu';
import DashboardButton from '../components/DashboardButton';
import { getMe } from '../redux/actions/api';
import { getTokenFromAsnycStorage, logOut } from '../redux/actions/auth';

import midiblack from '../images/midiiconblack.png';
import wavblack from '../images/waviconblack.png';
import downloadicon from '../images/downloadicon.png';
import logout from '../images/logout.png';

import 'react-perfect-scrollbar/dist/css/styles.css';
import '../style/audio.css';
import '../style/scroll.css';

const AWS = require('aws-sdk');

const s3 = new AWS.S3({
  accessKeyId: 'AKIAJZLDUVQ2Q5V3Q5CQ',
  secretAccessKey: '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',
  region: 'eu-central-1',
});

const SomeComponent = props => {
  const e = props.song;
  return (
    <div
      key={props.key}
      style={{
        backgroundColor:
          props.isSelected || props.isSelecting ? 'gray' : 'darkgray',
        width: '100vw',
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'row',
        margin: 5,
      }}
      ref={props.selectableRef}
    >
      {props.children}
    </div>
  );
};
const SelectableComponent = createSelectable(SomeComponent);

const DashboardTable = props => {
  return (
    <div className="flex w-screen mb-4">
      <div className="flex w-screen text-white font-display mx-auto pl-6 items-center justify-center">
        <div style={{ width: 250 }}>NAME</div>
        <div style={{ width: 200 }}>FORMAT</div>
        <div style={{ width: 100 }}>LENGTH</div>
        <div style={{ width: 100 }}>SIZE</div>
        <div style={{ width: 150 }}>WORKSPACE</div>
        <div style={{ width: 220 }}>PROJECT</div>
        <div style={{ width: 150 }}>CREATED ON</div>
      </div>
    </div>
  );
};

const DashboardTableRow = ({
  title,
  midi,
  duration,
  size,
  workspace,
  project,
  created,
}) => {
  return (
    <div className="flex w-screen">
      <div className="flex w-screen text-white font-alternate mx-auto items-center justify-center">
        <div className="font-display text-sm" style={{ width: 250 }}>
          {title}
        </div>
        <div style={{ width: 200 }} className="flex flex-row">
          <DashboardButton type="wav" />
          <div className="w-2" />
          {midi && <DashboardButton type="midi" />}
        </div>
        <div className="text-sm" style={{ width: 100 }}>
          {duration}
        </div>
        <div className="text-sm" style={{ width: 100 }}>
          {size}
        </div>
        <div className="text-sm" style={{ width: 150 }}>
          {workspace}
        </div>
        <div className="text-sm" style={{ width: 220 }}>
          {project}
        </div>
        <div className="text-sm" style={{ width: 150 }}>
          {created}
        </div>
      </div>
    </div>
  );
};
// for default első dal legyen kiválasztva

function Dashboard() {
  const dispatch = useDispatch();

  const api = useSelector(state => state.api);
  const auth = useSelector(state => state.auth);
  const [cookies, setCookie] = useCookies(['user']);
  const [loading, setLoading] = useState(false);
  const [selectedList, setSelectedList] = useState([]);
  const [isMidi, setIsMidi] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [audioUrl, setAudioUrl] = useState('');
  const [allSize, setAllSize] = useState(0);
  const [isDownloadAllMidi, setIsDownloadAllMidi] = useState(true);
  const [isDownloadAllWav, setIsDownloadAllWav] = useState(true);
  const [multipleSelect, setMultipleSelect] = useState(false);
  const [openPopup, setPopupopen] = useState(false);
  const audioplayerref = useRef(null);
  let _cookies = {};
  try {
    _cookies = JSON.parse(cookies?.user);
  } catch (error) {}

  if (!_cookies?.refresh_token) {
    setCookie('user', JSON.stringify(''), {
      path: '/',
    });
    if (typeof window !== `undefined`) {
      //navigate('/login');
    }
  }

  const loadSong = async (e, type, values, i) => {
    setLoading(true);
    const filename =
      type === 'wav'
        ? e.wavCloudPath
        : `${e.midiCloudPath.replace('.mid', '')}.mp3`;

    const params = {
      Bucket: 'kodaiusers',
      Key: `${e.owner}/${filename}`,
    };

    s3.getSignedUrl('getObject', params, async function(_err, uri) {
      const _selectedlist = values;
      _selectedlist.forEach((x, i) => {
        if (x.id === e.id) {
          if (type === 'midi') x.midiUrl = uri;
          if (type === 'wav') x.wavUrl = uri;
        }
      });
      if (i + 1 === values.length) {
        if (values.length === 1) {
          let url;
          console.log(values);
          if (isMidi && values[0].midiUrl) {
            url = values[0].midiUrl;
            console.log('url', url);
            setAudioUrl(url);
          } else {
            url = values[0].wavUrl;
            console.log('url', url);
            setAudioUrl(url);
          }
          console.log(url);
        }
      }
      console.log(_selectedlist);
      setSelectedList(values);

      setLoading(false);
    });
    if (type === 'midi') {
      s3.getSignedUrl(
        'getObject',
        {
          Bucket: 'kodaiusers',
          Key: `${e.owner}/${e.midiCloudPath}`,
        },
        async function(_err, uri) {
          const _selectedlist = values;
          _selectedlist.forEach((x, i) => {
            if (x.id === e.id) {
              x.midiUrlDownload = uri;
            }
          });
          console.log(_selectedlist);
          setSelectedList(values);
          setLoading(false);
        },
      );
    }
  };

  function switchMusic(prop) {
    audioplayerref.current.audio.current.pause();
    if (selectedList.length === 1) {
      let url;
      console.log(selectedList);
      if (!isMidi && selectedList[0].midiUrl) {
        url = selectedList[0].midiUrl;
        console.log('url', url);
        setAudioUrl(url);
      } else {
        url = selectedList[0].wavUrl;
        console.log('url', url);
        setAudioUrl(url);
      }
      audioplayerref.current.audio.current.load();
      audioplayerref.current.audio.current.play();
      console.log(url);
    }
    setIsMidi(!isMidi);
  }

  function handleSelecting(prop) {} // ez itt funkcionális?
  function handleSelectionClear(prop) {
    console.log('SELECTION', prop);
  }
  async function handleSelectionFinish(prop) {
    try {
      audioplayerref.current.audio.current.pause();
    } catch (error) {}
    const ids = prop.map(e => e._reactInternalFiber.key);
    const values = api.songs.filter(e => ids.find(x => e.id === x));
    setSelectedList([...values]);
    let sizes = 0;
    values.forEach((e, i) => {
      sizes += e.size;
      loadSong(e, 'wav', values, i);
      if (e.midiCloudPath) {
        loadSong(e, 'midi', values, i);
      }
    });
    setAllSize(bytesToSize(sizes));
  }
  async function handleSelectionOneFinish(e) {
    try {
      audioplayerref.current.audio.current.pause();
    } catch (error) {}
    setSelectedList([e]);
    loadSong(e, 'wav', [e], 0);
    if (e.midiCloudPath) {
      loadSong(e, 'midi', [e], 0);
    }
  }

  function msToMinutesAndSeconds(ms) {
    const min = Math.floor(ms / 60000);
    const sec = ((ms % 60000) / 1000).toFixed(0);
    return `${min}:${sec < 10 ? '0' : ''}${sec}`;
  }

  function bytesToSize(bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return `${Math.round(bytes / Math.pow(1024, i), 2)} ${sizes[i]}`;
  }

  useEffect(() => {
    try {
      console.log(cookies);
      if (auth.access_token) {
        /*if (api.songs.length === 0 || !api) {
          dispatch(getTokenFromAsnycStorage(auth));
        }*/
      } else if (cookies.user) {
        if (api.songs.length === 0 || !api) {
          const _user = JSON.parse(cookies.user);
          dispatch(getTokenFromAsnycStorage(_user));
        }
        // return navigate("/login");
      } else if (typeof window !== `undefined`) {
        return navigate('/');
      }
    } catch (error) {
      console.log(error);
    }
    return () => {
      console.log('return');
    };
  }, []);

  async function downloadAll() {
    const downloadList = [];
    const filenames = [];
    selectedList.forEach(async e => {
      if (e.wavUrl !== '' && isDownloadAllWav) {
        downloadList.push(e.wavUrl);
        filenames.push(`${e.songtitle}.wav`);
      }
      if (e.midiUrlDownload !== '' && isDownloadAllMidi) {
        downloadList.push(e.midiUrlDownload);
        filenames.push(`${e.songtitle}.mid`);
      }
    });
    // var FileSaver = require("file-saver");

    /*
    downloadList.forEach((e, i) => {
      //FileSaver.saveAs(e, Math.random().toString);
       JSZipUtils.getBinaryContent(e, function (err, data) {
        if (!err) {
          var dic = { base64: true, binary: true }; //WAV File Encoding
          zip.file(filenames[i], data, dic);
        }
      }); 
    });
     console.log(zip)
      zip.generateAsync({ type: "blob" }).then(function (content) {
        var a = document.querySelector("#" + 'id');
        a.download = 'Kodai';
        a.href = URL.createObjectURL(content);
        a.click();
      });
     */
  }

  async function downloadOne() {
    const FileSaver = require('file-saver');
    if (isMidi && selectedList[0].midiUrlDownload) {
      FileSaver.saveAs(selectedList[0].midiUrlDownload);
    } else {
      FileSaver.saveAs(selectedList[0].wavUrl);
    }
  }

  return (
    <div className="flex w-screen overflow-x-hidden">
      <SEO
        title="KODAI APP | Your files"
        description="Transcribe your audio recordings into midi files and music sheets using state of the art AI technology. Focus on your creative song-writing flow by saving time on music notation."
        url="/dashboard"
      />
      <DashboardMenu
        getMe={() => dispatch(getMe())}
        picture={api.user.img}
        pictureOnClick={() => setPopupopen(!openPopup)}
      />
      {openPopup && (
        <div className="fixed top-0 right-0 h-auto w-64 z-50 bg-white mt-24 mr-16 rounded">
          <div className="p-8">
            <div className="font-display uppercase mb-4">{`Hi ${
              api.user.fullname ? api.user.fullname : api.user.username
            }.`}</div>
            <button
              type="button"
              className="flex flex-row  items-center focus:outline-none"
              onClick={() => {
                dispatch(logOut());
                setCookie('user', JSON.stringify(''), {
                  path: '/',
                });
                if (typeof window !== `undefined`) {
                  navigate('/login');
                }
              }}
            >
              <img
                src={logout}
                alt="Search files"
                className="h-4 w-auto mr-2"
              />
              <p className="font-alternates text-sm">Sign out</p>
            </button>
          </div>
        </div>
      )}
      <div className="flex-col flex">
        <div className="flex h-screen w-screen bg-black">
          <div className="text-white flex flex-col bg-black mt-32 overflow-hidden dashboard_height">
            <DashboardTable />
            <PerfectScrollbar
              component="div"
              options={{ suppressScrollX: true }}
            >
              <div className="w-screen h-screen">
                {/* <p>Multiple select:</p>
            <Switch
              onChange={() => setMultipleSelect(!multipleSelect)}
              checked={multipleSelect}
            />
            <p style={{ color: "white" }}>Press Esc to deselect all</p>
            {multipleSelect && (
              <SelectableGroup
                className="main"
                clickClassName="tick"
                tolerance={0}
                deselectOnEsc={true}
                selectOnClick={true}
                allowShiftClick={true}
                globalMouse={true}
                allowClickWithoutSelected={true}
                duringSelection={handleSelecting}
                onSelectionClear={handleSelectionClear}
                onSelectionFinish={handleSelectionFinish}
                ignoreList={[".not-selectable"]}
              >
                {api.songs.map((e, i) => {
                  return (
                    <SelectableComponent key={e.id} song={e}>
                      <DashboardButton type="wav"/>
                      {e.midiCloudPath && (<DashboardButton type="midi"/>)}
                      <h2 style={{ color: "black", fontSize: 14 }}>
                        {e.songtitle}
                      </h2>
                      <h2 style={{ color: "black", fontSize: 14 }}>
                        {msToMinutesAndSeconds(e.duration * 1000)}
                      </h2>
                      <h2 style={{ color: "black", fontSize: 14 }}>
                        {bytesToSize(e.size)}
                      </h2>
                      <h2 style={{ color: "black", fontSize: 14 }}>
                        {e.projectName}
                      </h2>
                    </SelectableComponent>
                  );
                })}
              </SelectableGroup>
            )} */}
                {!multipleSelect && (
                  <div>
                    {api.songs.map((e, i) => {
                      return (
                        <div
                          onClick={() => handleSelectionOneFinish(e)}
                          className="w-screen flex flex-row justify-between m-2 py-2"
                          style={{
                            backgroundColor: selectedList.find(x => e === x)
                              ? '#727272'
                              : 'transparent',
                          }}
                          key={e.id}
                        >
                          <DashboardTableRow
                            title={e.songtitle}
                            midi={e.midiCloudPath}
                            duration={msToMinutesAndSeconds(e.duration * 1000)}
                            size={bytesToSize(e.size)}
                            workspace={e.workspaceName}
                            // api.workspaces.indexOf(e.workspaceName) !== -1 ? e.workspaceName : 'Shared with me'}
                            project={e.projectName}
                            created={e.createdAt.slice(0, 10)}
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </PerfectScrollbar>
          </div>
        </div>
        {/** *********************************************************** FOOTER *************************************************************************************************** */}
        {selectedList.length === 1 && (
          <div className="fixed left-0 bottom-0 z-20 h-30 flex flex-row bg-salmon p-4 text-black flex-no-wrap items-center justify-between w-screen px-16">
            <div className="flex flex-col item-center justify-center">
              <div className="font-display text-lg mb-4">
                {selectedList[0].songtitle}
              </div>
              <div className="flex flex-row items-center">
                <div className="w-30 flex justify-center items-center px-2 py-1">
                  <img className="h-6 w-auto mr-2" src={wavblack} />
                  <p clasName="font-display font-normal text-black">wav</p>
                </div>
                <Switch
                  uncheckedIcon={false}
                  checkedIcon={false}
                  disabled={selectedList[0].midiCloudPath === ''}
                  onChange={() => switchMusic()}
                  checked={isMidi}
                  offColor="#fff"
                  onColor="#fff"
                  offHandleColor="#000"
                  onHandleColor="#000"
                  handleDiameter={13}
                  height={20}
                  width={35}
                />
                <div className="w-30 flex justify-center items-center px-2 py-1">
                  <img className="h-6 w-auto mr-2" src={midiblack} />
                  <p clasName="font-display font-normal text-black">midi</p>
                </div>
              </div>
            </div>
            <div className="w-1/3">
              {console.log(audioUrl)}
              <AudioPlayer
                src={audioUrl}
                ref={audioplayerref}
                onPlay={e => console.log('onPlay')}
                showSkipControls={false}
                layout="stacked-reverse"
                customControlsSection={[
                  <div className="w-4" />,
                  'MAIN_CONTROLS',
                  <div className="w-4" />,
                ]}
                customProgressBarSection={[
                  'CURRENT_TIME',
                  <div className="w-4" />,
                  'PROGRESS_BAR',
                  <div className="w-4" />,
                  'DURATION',
                ]}
              />
            </div>
            <button
              className="mr-4 h-10 flex justify-center items-center bg-white rounded px-4 py-2  outline-none focus:outline-none shadow-md"
              onClick={() => downloadOne()}
              download
            >
              <img src={downloadicon} alt="Sync files" className="h-4 mr-2" />
              <p className="font-display text-black">DOWNLOAD</p>
            </button>
          </div>
        )}
        {selectedList.length > 1 && (
          <div className="fixed left-0 bottom-0 h-24 flex flex-row bg-salmon p-4 text-black flex-no-wrap items-center justify-between w-screen">
            <div>
              <div className="flex flex-row">
                {selectedList.length} recording selected
              </div>
              <div className="flex flex-row">{allSize}</div>
            </div>
            <div>
              <div className="flex flex-row">Select formats</div>
              <div className="flex flex-row">
                <div
                  style={{
                    backgroundColor: isDownloadAllWav ? 'blue' : 'white',
                  }}
                  onClick={() => {
                    setIsDownloadAllWav(!isDownloadAllWav);
                  }}
                >
                  Wav
                </div>
                <div
                  style={{
                    backgroundColor: isDownloadAllMidi ? 'blue' : 'white',
                  }}
                  onClick={() => setIsDownloadAllMidi(!isDownloadAllMidi)}
                >
                  Midi
                </div>
              </div>
            </div>
            <button
              className="mr-4 h-10 flex justify-center items-center bg-white rounded px-4 py-2  outline-none focus:outline-none shadow-md"
              onClick={() => downloadAll()}
              download
            >
              <img src={downloadicon} alt="Sync files" className="h-4 mr-2" />
              <p className="font-display text-black">DOWNLOAD</p>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Dashboard;
