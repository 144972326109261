import React from 'react';
import logo from '../../images/logotext.svg';
import logobg from '../../images/dashboardlogo.png';
import {Link} from 'gatsby';
import '../../style/styles.css';
import search from '../../images/search.png';
import syncicon from '../../images/syncicon.png';
import { Spin as Hamburger } from 'hamburger-react';

const DashboardButton = (prop) => {
  return (
    <div className="DashboardMenu fixed z-40 left-0 top-0 h-20 flex flex-row bg-black p-4 text-white flex-no-wrap items-center justify-between w-screen px-16">
      <img
          className="fixed top-0 left-0 ml-6 h-20"
          alt="Midi converter for ios and android"
          src={logobg}
        />
      <div >
        <img
          className="h-4 z-50 absolute"
          alt="Midi converter for ios and android"
          src={logo}
        />
      </div>
      {/*<div className="h-8 w-40 flex flex-row flex-no-wrap items-center bg-white rounded-full p-4">
        <img src={search} alt="Search files" className="h-4" />
        <div>Search</div>
      </div>*/}
      <div className="flex flex-row justify-center items-center">
        <button className='mr-4 h-10 flex justify-center items-center bg-white rounded px-4 py-2 outline-none focus:outline-none' onClick={prop.getMe}>
          <img src={syncicon} alt="Sync files" className="h-4 mr-2" />
          <p className="font-display text-black">REFRESH</p>
        </button>
        <button onClick={prop.pictureOnClick} className="focus:outline-none">
          {prop.picture ? (<img src={prop.picture} style={{height: 50, width: 50}} className="rounded-full" alt="profile" />) : (<Hamburger/>)}
        </button>
      </div>
    </div>
  );
};

export default DashboardButton;
