import React from 'react';
import midi from '../../images/midiicon.png';
import wav from '../../images/wavicon.png';

const DashboardButton = ({type}) => {
  return (
    <div className="h-8 w-26 flex justify-center items-center px-2 py-1">
      <img className="h-4 w-auto mr-2" src={type === 'midi'? midi : type === 'wav' ? wav : null}/>
      <p clasName="font-display text-black text-sm font-normal">{type === 'midi'? 'midi' : type === 'wav' ? 'wav' : ''}</p>
    </div>
  );
};

export default DashboardButton;
